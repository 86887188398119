import PropTypes from 'prop-types';
import {forwardRef} from 'react';
// @mui
import {useTheme} from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import {RouterLink} from 'src/routes/components';

// ----------------------------------------------------------------------

const baseLogo = (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.026 24.2997L16.5708 15.0105H12.7393V27.6062H15.5193V18.8555H15.6619L19.7964 27.6062H22.2022L26.4971 18.8555H26.6397V27.6062H29.4376V15.0105H25.7664L21.133 24.2997H21.026Z" fill="#212B36" />
    <path d="M29.373 19.4163L33.2527 19.4163C33.0895 18.7416 32.8739 18.0791 32.6071 17.4349C31.9325 15.8064 30.9438 14.3267 29.6975 13.0803C28.4511 11.8339 26.9714 10.8452 25.3429 10.1707C23.7144 9.49616 21.969 9.14898 20.2064 9.14898C18.4437 9.14898 16.6983 9.49616 15.0699 10.1707C13.9169 10.6483 12.8385 11.2834 11.8646 12.0559L11.7563 8.3092C12.431 7.90946 13.1347 7.55722 13.8625 7.25578C15.8737 6.42269 18.0294 5.9939 20.2064 5.9939C22.3834 5.9939 24.539 6.42269 26.5503 7.25578C28.5616 8.08888 30.3891 9.30997 31.9284 10.8493C33.4678 12.3887 34.6889 14.2162 35.522 16.2275C35.9489 17.258 36.2696 18.3265 36.4809 19.4163C36.6819 20.4534 36.7839 21.5099 36.7839 22.5714H29.373V21.1977V19.4163Z" fill="url(#paint0_linear_2367_2371)" />
    <path d="M12.7481 22.5835H8.74699C8.91015 23.2582 9.12577 23.9208 9.39262 24.565C10.0672 26.1935 11.0558 27.6732 12.3022 28.9195C13.5486 30.1659 15.0283 31.1546 16.6568 31.8292C18.2853 32.5037 20.0307 32.8509 21.7933 32.8509C23.556 32.8509 25.3014 32.5037 26.9298 31.8292C27.0708 31.7708 27.2107 31.71 27.3494 31.6469V35.0471C25.5677 35.681 23.6886 36.006 21.7933 36.006C19.6163 36.006 17.4607 35.5772 15.4494 34.7441C13.4381 33.911 11.6106 32.6899 10.0713 31.1505C8.53189 29.6112 7.3108 27.7837 6.47771 25.7724C6.05083 24.7418 5.73011 23.6734 5.51883 22.5835C5.31777 21.5464 5.21582 20.49 5.21582 19.4285L12.7481 19.4285V20.9709V22.5835Z" fill="url(#paint1_linear_2367_2371)" />
    <defs>
      <linearGradient id="paint0_linear_2367_2371" x1="21.9707" y1="6.00604" x2="21.728" y2="23.2376" gradientUnits="userSpaceOnUse">
        <stop stopColor="#418DFB" />
        <stop offset="1" stopColor="#26F971" />
      </linearGradient>
      <linearGradient id="paint1_linear_2367_2371" x1="16.2825" y1="37.0715" x2="16.2825" y2="19.4304" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FEE60C" />
        <stop offset="8.37095e-05" stopColor="#FD3172" />
        <stop offset="1" stopColor="#FEE10F" />
      </linearGradient>
    </defs>
  </svg>
)

const mdLogo = (
  <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28.5349 32.3993L22.5945 20.0137H17.4858V36.8079H21.1926V25.1403H21.3827L26.8953 36.8079H30.1031L35.8296 25.1403H36.0197V36.8079H39.7502V20.0137H34.8554L28.6774 32.3993H28.5349Z" fill="#212B36" />
    <path d="M39.6641 25.8878H44.837C44.6195 24.9882 44.332 24.1048 43.9762 23.2458C43.0768 21.0745 41.7586 19.1016 40.0967 17.4398C38.4349 15.7779 36.462 14.4597 34.2907 13.5603C32.1194 12.6609 29.7922 12.198 27.4419 12.198C25.0917 12.198 22.7645 12.6609 20.5932 13.5603C19.0559 14.1971 17.618 15.0438 16.3196 16.0738L16.1752 11.0783C17.0748 10.5453 18.013 10.0756 18.9834 9.67373C21.6651 8.56293 24.5393 7.99121 27.4419 7.99121C30.3446 7.99121 33.2188 8.56293 35.9005 9.67373C38.5822 10.7845 41.0189 12.4126 43.0714 14.4651C45.1238 16.5176 46.752 18.9543 47.8628 21.636C48.4319 23.01 48.8595 24.4347 49.1412 25.8878C49.4093 27.2706 49.5453 28.6792 49.5453 30.0945H39.6641V28.2629V25.8878Z" fill="url(#paint0_linear_2699_59206)" />
    <path d="M17.4975 30.1116H12.1627C12.3802 31.0111 12.6677 31.8945 13.0235 32.7535C13.9229 34.9248 15.2412 36.8977 16.903 38.5596C18.5649 40.2214 20.5378 41.5396 22.7091 42.439C24.8804 43.3384 27.2076 43.8013 29.5578 43.8013C31.908 43.8013 34.2352 43.3384 36.4065 42.439C36.5945 42.3612 36.781 42.2802 36.9659 42.1961V46.7297C34.5903 47.5748 32.0848 48.0081 29.5578 48.0081C26.6551 48.0081 23.7809 47.4364 21.0992 46.3256C18.4175 45.2148 15.9809 43.5867 13.9284 41.5342C11.8759 39.4817 10.2478 37.0451 9.13698 34.3634C8.56782 32.9893 8.14019 31.5647 7.85848 30.1116C7.5904 28.7287 7.45447 27.3201 7.45447 25.9048L17.4975 25.9048V27.9613V30.1116Z" fill="url(#paint1_linear_2699_59206)" />
    <defs>
      <linearGradient id="paint0_linear_2699_59206" x1="29.7943" y1="8.00741" x2="29.4707" y2="30.9829" gradientUnits="userSpaceOnUse">
        <stop stopColor="#418DFB" />
        <stop offset="1" stopColor="#26F971" />
      </linearGradient>
      <linearGradient id="paint1_linear_2699_59206" x1="22.21" y1="49.4288" x2="22.21" y2="25.9074" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FEE60C" />
        <stop offset="8.37095e-05" stopColor="#FD3172" />
        <stop offset="1" stopColor="#FEE10F" />
      </linearGradient>
    </defs>
  </svg>
)

const fullLogo = (
  <svg width="164" height="42" viewBox="0 0 164 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.026 24.2999L16.5708 15.0106H12.7393V27.6063H15.5193V18.8556H15.6619L19.7964 27.6063H22.2022L26.4971 18.8556H26.6397V27.6063H29.4376V15.0106H25.7664L21.133 24.2999H21.026Z" fill="#212B36" />
    <path d="M29.373 19.4164L33.2527 19.4164C33.0895 18.7417 32.8739 18.0792 32.6071 17.4349C31.9325 15.8064 30.9438 14.3268 29.6975 13.0804C28.4511 11.834 26.9714 10.8453 25.3429 10.1708C23.7144 9.49622 21.969 9.14904 20.2064 9.14904C18.4437 9.14904 16.6983 9.49622 15.0699 10.1708C13.9169 10.6483 12.8385 11.2834 11.8646 12.0559L11.7563 8.30926C12.431 7.90952 13.1347 7.55729 13.8625 7.25584C15.8737 6.42275 18.0294 5.99396 20.2064 5.99396C22.3834 5.99396 24.539 6.42275 26.5503 7.25584C28.5616 8.08894 30.3891 9.31003 31.9284 10.8494C33.4678 12.3888 34.6889 14.2162 35.522 16.2275C35.9489 17.2581 36.2696 18.3265 36.4809 19.4164C36.6819 20.4535 36.7839 21.5099 36.7839 22.5714H29.373V21.1977V19.4164Z" fill="url(#paint0_linear_2367_551)" />
    <path d="M12.7481 22.5836H8.74699C8.91015 23.2583 9.12577 23.9208 9.39262 24.5651C10.0672 26.1935 11.0558 27.6732 12.3022 28.9196C13.5486 30.166 15.0283 31.1547 16.6568 31.8292C18.2853 32.5038 20.0307 32.8509 21.7933 32.8509C23.556 32.8509 25.3014 32.5038 26.9298 31.8292C27.0708 31.7708 27.2107 31.7101 27.3494 31.647V35.0472C25.5677 35.681 23.6886 36.006 21.7933 36.006C19.6163 36.006 17.4607 35.5772 15.4494 34.7441C13.4381 33.911 11.6106 32.6899 10.0713 31.1506C8.53189 29.6112 7.3108 27.7837 6.47771 25.7725C6.05083 24.7419 5.73011 23.6734 5.51883 22.5836C5.31777 21.5465 5.21582 20.49 5.21582 19.4285L12.7481 19.4285V20.9709V22.5836Z" fill="url(#paint1_linear_2367_551)" />
    <g clipPath="url(#clip0_2367_551)">
      <path fillRule="evenodd" clipRule="evenodd" d="M80.2136 27.69C76.3728 27.69 73.1338 25.0131 73.1338 21.0159C73.1338 17.0186 76.3728 14.3419 80.2136 14.3419C84.0544 14.3419 87.2934 17.0186 87.2934 21.0159C87.2934 25.0131 84.0544 27.69 80.2136 27.69ZM80.2136 25.2274C82.5853 25.2274 84.4438 23.6391 84.4438 21.0159C84.4438 18.3926 82.603 16.8045 80.2136 16.8045C77.8419 16.8045 75.9834 18.3926 75.9834 21.0159C75.9834 23.6391 77.8419 25.2274 80.2136 25.2274Z" fill="#10151D" />
      <path d="M121.777 14.6454V17.2508H111.883V19.6064H120.91V22.1938H111.883V24.728H122.096V27.369H109.033V14.6454H121.777Z" fill="#10151D" />
      <path d="M130.115 14.6454L137.673 23.211V14.6454H140.505V27.369H137.797L130.239 18.7855V27.369H127.425V14.6454H130.115Z" fill="#10151D" />
      <path d="M91.4342 14.6995H103.204V17.0015L94.9387 24.7819H103.204V27.2982H91.4342V24.5856L99.4875 17.2336H91.4342V14.6995Z" fill="#10151D" />
      <path d="M60.2303 23.8714L55.8054 14.6454H52V27.369H54.7611V18.4642H54.9027L59.009 27.369H61.3985L65.6641 18.4642H65.8057V27.369H68.5845V14.6454H64.9384L60.3365 23.8714H60.2303Z" fill="#10151D" />
      <path d="M1155.5 -1339.94L1151.07 -1349.17H1147.27V-1336.44H1150.03V-1345.35H1150.17L1154.28 -1336.44H1156.67L1160.93 -1345.35H1161.07V-1336.44H1163.85V-1349.17H1160.21L1155.6 -1339.94H1155.5Z" fill="#10151D" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_2367_551" x1="21.9707" y1="6.0061" x2="21.728" y2="23.2377" gradientUnits="userSpaceOnUse">
        <stop stopColor="#418DFB" />
        <stop offset="1" stopColor="#26F971" />
      </linearGradient>
      <linearGradient id="paint1_linear_2367_551" x1="16.2825" y1="37.0715" x2="16.2825" y2="19.4305" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FEE60C" />
        <stop offset="8.37095e-05" stopColor="#FD3172" />
        <stop offset="1" stopColor="#FEE10F" />
      </linearGradient>
      <clipPath id="clip0_2367_551">
        <rect width="88.7755" height="13.3163" fill="white" transform="translate(52 14.3419)" />
      </clipPath>
    </defs>
  </svg>
)

const logo32 = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="8" />
    <path d="M16.0199 18.514L12.6254 11.4365H9.70618V21.0332H11.8243V14.366H11.933L15.083 21.0332H16.9161L20.1883 14.366H20.297V21.0332H22.4287V11.4365H19.6316L16.1014 18.514H16.0199Z" fill="#212B36" />
    <path d="M22.3795 14.7935L25.3355 14.7935C25.2111 14.2795 25.0469 13.7747 24.8435 13.2838C24.3296 12.0431 23.5763 10.9157 22.6267 9.96607C21.6771 9.01644 20.5497 8.26315 19.3089 7.74922C18.0682 7.23528 16.7384 6.97077 15.3954 6.97077C14.0524 6.97077 12.7226 7.23528 11.4819 7.74922C10.6034 8.11309 9.78174 8.59695 9.03978 9.18554L8.95728 6.33093C9.47132 6.02637 10.0075 5.758 10.5619 5.52833C12.0943 4.89359 13.7367 4.56689 15.3954 4.56689C17.0541 4.56689 18.6965 4.89359 20.2289 5.52833C21.7613 6.16307 23.1536 7.09343 24.3265 8.26627C25.4993 9.43912 26.4297 10.8315 27.0644 12.3639C27.3897 13.1491 27.634 13.9631 27.795 14.7935C27.9482 15.5837 28.0259 16.3886 28.0259 17.1974H22.3795V16.1507V14.7935Z" fill="url(#paint0_linear_3258_38028)" />
    <path d="M9.7129 17.2066H6.66441C6.78872 17.7206 6.95301 18.2254 7.15632 18.7163C7.67026 19.957 8.42354 21.0844 9.37317 22.034C10.3228 22.9837 11.4502 23.7369 12.6909 24.2509C13.9317 24.7648 15.2615 25.0293 16.6045 25.0293C17.9474 25.0293 19.2773 24.7648 20.518 24.2509C20.6254 24.2064 20.732 24.1601 20.8377 24.112V26.7027C19.4802 27.1856 18.0485 27.4332 16.6045 27.4332C14.9458 27.4332 13.3034 27.1065 11.771 26.4718C10.2386 25.837 8.84622 24.9067 7.67338 23.7338C6.50053 22.561 5.57018 21.1686 4.93544 19.6362C4.6102 18.851 4.36584 18.0369 4.20487 17.2066C4.05168 16.4164 3.974 15.6115 3.974 14.8027L9.7129 14.8027V15.9779V17.2066Z" fill="url(#paint1_linear_3258_38028)" />
    <defs>
      <linearGradient id="paint0_linear_3258_38028" x1="16.7396" y1="4.57615" x2="16.5547" y2="17.705" gradientUnits="userSpaceOnUse">
        <stop stopColor="#418DFB" />
        <stop offset="1" stopColor="#26F971" />
      </linearGradient>
      <linearGradient id="paint1_linear_3258_38028" x1="12.4058" y1="28.245" x2="12.4058" y2="14.8042" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FEE60C" />
        <stop offset="8.37095e-05" stopColor="#FD3172" />
        <stop offset="1" stopColor="#FEE10F" />
      </linearGradient>
    </defs>
  </svg>
)

const logo24 = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="6" fill="white" />
    <path d="M12.0151 13.8853L9.46923 8.57715H7.27979V15.7747H8.8684V10.7743H8.94987L11.3124 15.7747H12.6872L15.1414 10.7743H15.2229V15.7747H16.8217V8.57715H14.7239L12.0762 13.8853H12.0151Z" fill="#212B36" />
    <path d="M16.7849 11.0952L19.0019 11.0952C18.9087 10.7097 18.7854 10.3311 18.633 9.96298C18.2475 9.03242 17.6825 8.18689 16.9703 7.47467C16.2581 6.76245 15.4126 6.19749 14.482 5.81204C13.5515 5.42659 12.5541 5.2282 11.5469 5.2282C10.5396 5.2282 9.54225 5.42659 8.61169 5.81204C7.95284 6.08494 7.33661 6.44784 6.78014 6.88927L6.71826 4.74832C7.10379 4.5199 7.5059 4.31862 7.92175 4.14637C9.07105 3.67032 10.3029 3.42529 11.5469 3.42529C12.7908 3.42529 14.0227 3.67032 15.172 4.14637C16.3213 4.62243 17.3655 5.32019 18.2452 6.19983C19.1248 7.07946 19.8226 8.12374 20.2986 9.27304C20.5426 9.86193 20.7258 10.4725 20.8466 11.0952C20.9614 11.6879 21.0197 12.2916 21.0197 12.8981H16.7849V12.1132V11.0952Z" fill="url(#paint0_linear_3258_38293)" />
    <path d="M7.28464 12.905H4.99828C5.09151 13.2905 5.21472 13.6691 5.36721 14.0372C5.75266 14.9678 6.31763 15.8133 7.02985 16.5255C7.74207 17.2377 8.5876 17.8027 9.51816 18.1882C10.4487 18.5736 11.4461 18.772 12.4533 18.772C13.4605 18.772 14.4579 18.5736 15.3885 18.1882C15.469 18.1548 15.549 18.1201 15.6282 18.084V20.027C14.6101 20.3892 13.5363 20.5749 12.4533 20.5749C11.2093 20.5749 9.97751 20.3299 8.82821 19.8538C7.67892 19.3778 6.63464 18.68 5.755 17.8004C4.87537 16.9207 4.1776 15.8765 3.70155 14.7272C3.45762 14.1383 3.27435 13.5277 3.15362 12.905C3.03873 12.3123 2.98047 11.7086 2.98047 11.1021L7.28464 11.1021V11.9834V12.905Z" fill="url(#paint1_linear_3258_38293)" />
    <defs>
      <linearGradient id="paint0_linear_3258_38293" x1="12.555" y1="3.43223" x2="12.4163" y2="13.2789" gradientUnits="userSpaceOnUse">
        <stop stop-color="#418DFB" />
        <stop offset="1" stop-color="#26F971" />
      </linearGradient>
      <linearGradient id="paint1_linear_3258_38293" x1="9.30429" y1="21.1838" x2="9.30429" y2="11.1032" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FEE60C" />
        <stop offset="8.37095e-05" stop-color="#FD3172" />
        <stop offset="1" stop-color="#FEE10F" />
      </linearGradient>
    </defs>
  </svg>

)

const getParams = (type) => {
  switch (type) {
    case 'logo32':
      return {
        width: 32,
        height: 32,
        icon: logo32
      }

    case 'logo24':
      return {
        width: 24,
        height: 24,
        icon: logo24
      }

    case 'mdLogo':
      return {
        width: 56,
        height: 56,
        icon: mdLogo
      }

    case 'fullLogo':
      return {
        width: 160,
        height: 40,
        icon: fullLogo
      }

    default:
      return {
        width: 40,
        height: 40,
        icon: baseLogo
      }
  }
}

const Logo = forwardRef(({disabledLink = false, isFullLogo = false, isMdLogo = false, type = "", sx, ...other}, ref) => {

  const {width, height, icon} = getParams(type)

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        height,
        width,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      {icon}
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{display: 'contents'}}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
