import React from 'react'
import {
  Dialog,
} from '@mui/material'
import NotificationsContent from './NotificationsContent'

export function ModalNotifications(props) {
  const {hideModal, open, ...rest} = props

  return (
    <Dialog
      fullScreen
      open={open}
      keepMounted
      onClose={hideModal}
      sx={{backgroundColor: "white"}}
    >
      <NotificationsContent
        close={hideModal}
        {...rest}
      />
    </Dialog>
  )
}
