import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import {fToNow} from 'src/utils/format-time';
import {notificationsTypes} from 'src/store/notifications/notificationsTypes';

export default function NotificationCustomItem({
  notification,
  setSelectedNotification,
}) {

  const isUnRead = notification.status === 'new'
  const renderAvatar = (
    <ListItemAvatar>
      {notification.notification_type ? (
        notificationsTypes[notification.notification_type]?.listAvatar
      ) : (
        <Avatar src={notification.avatarUrl} sx={{height: '32px', width: '32px'}} />
      )}
    </ListItemAvatar>
  );

  const renderText = (
    <ListItemText
      disableTypography
      primary={
        <Box sx={{color: isUnRead ? '#212B36' : '#637381'}}>
          {reader(notification.title)}
        </Box>
      }
      secondary={
        <Box>
          {notification.short_message && (
            <Typography variant='body2' color='#454F5B' mt={1} mb={1.5}>
              {notification.short_message}
            </Typography>
          )}
          <Stack
            direction="row"
            alignItems="center"
            sx={{typography: 'caption', color: 'text.disabled'}}
            divider={
              <Box
                sx={{
                  width: 2,
                  height: 2,
                  bgcolor: 'currentColor',
                  mx: 1,
                  borderRadius: '50%',
                }}
              />
            }
          >
            {notificationsTypes[notification.notification_type]?.label}
            {fToNow(notification.created_at)}
          </Stack>
        </Box>
      }
    />
  );

  const renderUnReadBadge = isUnRead && (
    <Box
      sx={{
        top: 26,
        width: 8,
        height: 8,
        right: 20,
        borderRadius: '50%',
        bgcolor: '#1E32E9',
        position: 'absolute',
      }}
    />
  );

  return (
    <ListItemButton
      disableRipple
      sx={{
        p: 2,
        alignItems: 'flex-start',
        borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
      }}
      onClick={() => setSelectedNotification(notification)}
    >
      {renderUnReadBadge}

      {renderAvatar}

      <Stack sx={{flexGrow: 1, pr: 2}}>
        {renderText}
      </Stack>
    </ListItemButton>
  );
}

function reader(data) {
  return (
    <Box
      dangerouslySetInnerHTML={{__html: data}}
      sx={{
        mb: 1.5, typography: 'subtitle2', color: 'inherit',
      }}
    />
  );
}
