import {createSlice} from "@reduxjs/toolkit"

const initialState = {
  notifications: {
    loading: false,
    data: null,
  },
  selectedNotification: {
    loading: false,
    data: null,
  },
  notificationMessages: [],
  notificationsCount: null,
  openNotifications: false,
  globalNotificationsFilters: null
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    fetch: (state, action) => {

    },
    fetchMessages: (state, action) => {

    },

    pollMessages: (state, action) => {

    },

    stopPollMessages: (state, action) => {

    },
    markView: (state, action) => {

    },
    fetchId: (state, action) => {

    },
    setLoading(state, action) {
      const {payload} = action
      state[payload.type].loading = payload.loading
    },
    setNotifications(state, action) {
      const {payload} = action
      state.notifications.data = payload.data
    },
    setSelectedNotification(state, action) {
      const {payload} = action
      state.selectedNotification.data = payload.data
    },

    setNotificationsCount(state, action) {
      const {payload} = action
      state.notificationsCount = payload
    },

    setOpenNotifications(state, action) {
      const {payload} = action
      state.openNotifications = payload
    },

    setGlobalNotificationsFilters(state, action) {
      const {payload} = action
      state.globalNotificationsFilters = payload
    },

    addNotificationMessages(state, action) {
      const {payload} = action
      const notification = {
        key: `${Date.now().toString(36) + Math.random().toString(36)}`,
        title: '',
        text: '',
        autoClose: true,
        ...payload,
      }
      state.notificationMessages = [
        ...state.notificationMessages,
        notification,
      ]
    },
    removeNotificationMessages(state, action) {
      const {payload} = action
      state.notificationMessages = state.notificationMessages.filter(message => message.key !== payload.key)
    },
  },
})
const notificationsReducer = notificationsSlice.reducer

export {notificationsSlice, notificationsReducer}
