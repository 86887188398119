import React from 'react'
import {Box, Card, CardContent, Skeleton, Stack} from '@mui/material';


export default function LoadingDataSmall({
  loading,
}) {
  if (!loading) {
    return null
  }

  return (
    <>
      {Array(3).fill(1).map((_, index) => {
        return (
          <Card key={index} sx={{mt: 3}}>
            <CardContent>
              <Stack spacing={1}>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                  <Skeleton variant="rectangular" width={360} height={26} />
                  <Skeleton variant="rectangular" width={86} height={26} />
                </Box>
                <Skeleton variant="rectangular" width={160} height={21} />
                <Skeleton variant="rectangular" width={160} height={21} />
              </Stack>
            </CardContent>
          </Card>
        )
      })}
    </>
  )
}
