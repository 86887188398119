import React from 'react'
import {Box, Chip} from '@mui/material';

export default function AppliedFilters({
  filters,
  onDelete,
  ...props
}) {
  return (
    <Box style={{display: "flex", gap: "8px", flexWrap: "wrap"}} {...props}>
      {filters?.map(({type, label} = {}, index) => {
        return (
          <Chip
            key={type + index}
            label={label}
            sx={{
              borderRadius: 100,
              fontWeight: 400,
              backgroundColor: "#E8ECF1",
              color: "#212B36",
              fontSize: 14,
              border: "1px solid transparent",
              '&&:hover': {
                backgroundColor: "#E8ECF1",
              }
            }}
            onDelete={() => onDelete?.({label, type})}
          />
        )
      })}
    </Box >
  )
}

