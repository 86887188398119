import {
  DialogTitle,
  Stack,
  IconButton,
} from '@mui/material'
import {useResponsive} from 'src/hooks/use-responsive'
import CloseIcon from '@mui/icons-material/Close';

const DialogHeader = ({
  children,
  close,
  sx
}) => {

  const smUp = useResponsive('up', 'sm');

  return (
    <DialogTitle
      sx={{
        padding: "20px",
        ...(smUp && {
          minHeight: '84px',
        }),
        ...sx,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        {children}
        <IconButton size="large" onClick={close}>
          <CloseIcon size="small" />
        </IconButton>
      </Stack>
    </DialogTitle>
  )
}

export default DialogHeader
