import {useEffect} from "react";
import {appConfig} from "src/config/appConfig";

const omnidesc = (appConfig.environment.REACT_APP_OMNIDESK_WIDGET_ID) ?
  () => {
    void function (e, o) {
      window.omni = window.omni ?? []
      window.omni.push(o);
      o.g_config = {widget_id: appConfig.environment.REACT_APP_OMNIDESK_WIDGET_ID};
      o.email_widget = o.email_widget || {};
      let w = o.email_widget;
      w.readyQueue = [];
      o.config = function (e) {
        this.g_config.user = e
      };
      w.ready = function (e) {
        this.readyQueue.push(e)
      };
      let r = e.getElementsByTagName("script")[0];
      let c = e.createElement("script");
      c.type = "text/javascript"
      c.async = !0
      c.src = "https://omnidesk.ru/bundles/acmesite/js/cwidget0.2.min.js"
      r.parentNode.insertBefore(c, r)
    }(document, [])
  } : () => {};

export const useOmnidesk = () => {

  useEffect(() => {
    if (appConfig.environment.REACT_APP_OMNIDESK_WIDGET_ID) {
      omnidesc()
      if (document.getElementById('omni_widget_iframe_' + appConfig.environment.REACT_APP_OMNIDESK_WIDGET_ID)) {
        document.getElementById('omni_widget_iframe_' + appConfig.environment.REACT_APP_OMNIDESK_WIDGET_ID).style.display = 'block'
      }

      return () => {
        if (document.getElementById('omni_widget_iframe_' + appConfig.environment.REACT_APP_OMNIDESK_WIDGET_ID)) {
          document.getElementById('omni_widget_iframe_' + appConfig.environment.REACT_APP_OMNIDESK_WIDGET_ID).style.display = 'none'
        }
      }
    }
  }, [])

  return null;
};
