import React from 'react'
import NotificationsList from './NotificationsList'
import Notification from './Notification'
import NotificationsFilters from './NotificationsFilters'

export default function NotificationsContent(props) {
  const {
    selectedNotification,
    setSelectedNotification,
    notifications,
    tab,
    textFilter,
    onClearText,
    onChangeTabs,
    onChangeText,
    resetNotificationsLayout,
    filtersView,
    dateFilter,
    onResetFilters,
    updateTypeFilter,
    updateDateFilter,
    showDateModal,
    rangeDateLabel,
    showFilters,
    onApplyFilters,
    typeFilter,
    appliedFilters,
    onDeleteAppliedFilters,
    loading,
    onResetEmpty,
    readAll,
    unreadIds,
    close
  } = props

  if (filtersView) {
    return (
      <NotificationsFilters
        dateFilter={dateFilter}
        updateDateFilter={updateDateFilter}
        onResetFilters={onResetFilters}
        showDateModal={showDateModal}
        rangeDateLabel={rangeDateLabel}
        resetNotificationsLayout={resetNotificationsLayout}
        onApplyFilters={onApplyFilters}
        typeFilter={typeFilter}
        updateTypeFilter={updateTypeFilter}
        close={close}
      />
    )
  }
  if (selectedNotification) {
    return (
      <Notification
        selectedNotification={selectedNotification}
        resetNotificationsLayout={resetNotificationsLayout}
      />
    )
  }

  return (
    <NotificationsList
      setSelectedNotification={setSelectedNotification}
      tab={tab}
      notifications={notifications}
      textFilter={textFilter}
      onClearText={onClearText}
      onChangeTabs={onChangeTabs}
      onChangeText={onChangeText}
      showFilters={showFilters}
      appliedFilters={appliedFilters}
      onDeleteAppliedFilters={onDeleteAppliedFilters}
      loading={loading}
      onResetEmpty={onResetEmpty}
      readAll={readAll}
      unreadIds={unreadIds}
      close={close}
    />
  )
}

