import {createSlice} from "@reduxjs/toolkit"

const initialState = {
  "loading": false,
  "user": null,
  "method": "jwt",
  "tokens": null,
  "permissions": null,
  balances: null,
  limits: null,
  allPermissions: null,
  otp: null,
  login: null,
  userDisabled: false,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    restore: (state, action) => {

    },
    otpCheck: (state, action) => {

    },

    userLimit: (state, action) => {

    },

    userBalances: (state, action) => {

    },
    logout(state) {

    },
    login(state, payload) {

    },
    setUserLimit(state, action) {
      state.limits = action.payload.limits
    },

    setUserBalances(state, action) {
      state.balances = action.payload.balances
    },
    setUser(state, action) {
      const {user, tokens, permissions, balances, limits, allPermissions, otp} = action.payload

      state.user = user
      state.permissions = permissions
      state.tokens = tokens
      state.balances = balances
      state.limits = limits
      state.allPermissions = allPermissions
      state.otp = otp
    },
    setLoading(state, action) {
      const {payload} = action
      state.loading = payload
    },

    setOtp(state, action) {
      state.otp = action.payload
    },

    setLogin(state, action) {
      state.login = action.payload
    },

    setUserDisabled(state, action) {
      state.userDisabled = action.payload
    },
  },
})

const userReducer = userSlice.reducer

export {userSlice, userReducer}
