import React from 'react'
import CustomDrawer from 'src/components/custom-drawer/CustomDrawer';
import NotificationsContent from './NotificationsContent';

export default function DrawerNotifications({
  open,
  onClose,
  ...rest
}) {

  return (
    <CustomDrawer
      open={open}
      onClose={onClose}
    >
      <NotificationsContent
        close={onClose}
        {...rest}
      />
    </CustomDrawer>
  )
}

