import PropTypes from 'prop-types';
// @mui
import {useTheme} from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
// theme
import {bgBlur} from 'src/theme/css';
// hooks
import {useOffSetTop} from 'src/hooks/use-off-set-top';
import {useResponsive} from 'src/hooks/use-responsive';
// components
import {useSettingsContext} from 'src/components/settings';
//
import {HEADER, NAV} from '../config-layout';
import {
  AccountPopover,
  ContactsPopover,
} from '../_common';
import MenuIcon from 'src/assets/icons/menu-icon'
import {Badge, badgeClasses} from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import {Notifications} from 'src/components/notifications/Notifications';

// ----------------------------------------------------------------------

export default function Header({onOpenNav}) {
  const theme = useTheme();

  const settings = useSettingsContext();

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');
  const smUp = useResponsive('up', 'sm');
  const smDown = useResponsive('down', 'sm');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;


  const renderContent = (
    <>
      {!lgUp && (
        <IconButton onClick={onOpenNav} sx={{width: '44px', height: '44px'}}>
          <MenuIcon />
        </IconButton>
      )}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{xs: 0.5, sm: 1.5}}
        gap='12px'
      >
        <ContactsPopover smDown={smDown} />

        <Notifications />

        <AccountPopover smDown={smDown} />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(smDown && {
          borderBottom: '1px solid #E8ECF1',
          background: '#FFFFFF',
        }),
        ...(smUp && {
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          background: '#F4F6F8',
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: {lg: 5},
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
