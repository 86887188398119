import React from 'react'
import {Drawer} from '@mui/material';

export default function CustomDrawer({
  children,
  ...rest
}) {
  return (
    <Drawer
      anchor='right'
      PaperProps={{
        style: {
          maxWidth: '400px',
          width: '100%',
        }
      }}
      ModalProps={{
        BackdropProps: {
          invisible: true
        }
      }}
      {...rest}
    >
      {children}
    </Drawer>
  )
}

