import {
  DialogActions,
  Stack,
} from '@mui/material'

const DialogFooter = ({
  children,
  sx
}) => {

  return (
    <DialogActions
      sx={{
        borderTop: '1px solid #E5E8EB',
        background: '#fff',
        width: '100%',
        padding: '20px',
        zIndex: '99999',
        ...sx
      }}
    >
      <Stack
        sx={{
          width: "100%",
        }}
        direction="row"
        spacing="16px"
        justifyContent="space-between"
        alignItems="center"
      >
        {children}
      </Stack>
    </DialogActions>
  )
}

export default DialogFooter
