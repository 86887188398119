import {api} from '../api'

export const notificationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetch: build.query({
      query(payload) {
        return {
          url: `/notification/`,
          method: 'GET',
          params: {
            status: payload.status,
            notification_type: payload.notification_type,
            text: payload.text,
            ...payload?.date,
            ...payload?.dateRange,
          }
        }
      },
    }),
    read: build.query({
      query(payload) {
        const formData = new FormData()
        formData.append("ids", payload?.ids);

        return {
          url: `/notification/`,
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data;'
          },
          data: formData,
        }
      },
    }),
  }),
})
