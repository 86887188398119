import {
  DialogContent,
  DialogContentText,
} from '@mui/material'

const DialogBody = ({
  children,
  sx
}) => {

  return (
    <DialogContent
      sx={{
        padding: '20px',
        background: '#F4F6F8',
        ...sx,
      }}
    >
      <DialogContentText
        id="scroll-dialog-description"
        tabIndex={-1}
        as="div"
      >
        {children}
      </DialogContentText>
    </DialogContent>
  )
}

export default DialogBody
