import Box from '@mui/material/Box'
import React, {useEffect, useState} from 'react'
import {Snackbar, Typography} from '@mui/material'
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'
import {useDispatch, useSelector} from 'react-redux'
import {notificationsSlice} from 'src/store/notifications/notificationsSlice'
import {ReactComponent as Mail} from 'src/assets/icons/mail-24.svg';

export default function NotificationMessages() {
  const notifications = useSelector(store => store.notifications.notificationMessages)
  const dispatch = useDispatch()
  const {user} = useSelector(store => store.user)
  const [autoClosed, setAutoClosed] = useState([])

  const handleClose = (message) => {
    if (message) {
      dispatch(notificationsSlice.actions.removeNotificationMessages(message))
    }
  }

  const handleClick = () => {
    if (user) {
      dispatch(notificationsSlice.actions.setOpenNotifications(true))
      dispatch(notificationsSlice.actions.setGlobalNotificationsFilters({status: 'new'}))
    }
  }

  useEffect(() => {
    notifications.forEach(message => {
      if (message.autoClose && !autoClosed.includes(message.key)) {
        setAutoClosed([...autoClosed, message.key])
        setTimeout(() => dispatch(notificationsSlice.actions.removeNotificationMessages(message)), 5e3)
      }
    })
  }, [notifications, autoClosed])

  useEffect(() => {
    if (user) {
      dispatch(notificationsSlice.actions.pollMessages())
    }
  }, [user]);

  return (
    <Snackbar
      open={notifications.length > 0}
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
    >
      <Box sx={{width: '100%'}}>
        <Stack spacing={2} direction="column" sx={{maxWidth: '343px', width: '100%'}}>
          {notifications.map(message => {
            return (
              <Alert
                key={message.key}
                onClose={(e) => {
                  e.stopPropagation()
                  handleClose(message)
                }}
                onClick={() => {
                  handleClick()
                }}
                sx={{
                  width: '100%',
                  border: '1px solid #EC928E',
                  backgroundColor: '#fff',
                  borderRadius: '16px',
                  ['.MuiAlert-message']: {
                    overflow: 'visible'
                  }
                }}
                icon={
                  <Box sx={{position: 'relative'}}>
                    <Box sx={{
                      position: 'absolute',
                      borderRadius: '100%',
                      backgroundColor: '#DC362E',
                      right: '-2px',
                      top: '0',
                      width: '10px',
                      height: '10px',
                      border: '1px solid #fff'
                    }} />
                    <Mail />
                  </Box>
                }
              >
                <Stack>

                </Stack>
                <Typography variant='subtitle1' sx={{
                  color: '#212B36',
                }}>
                  {message.title}
                </Typography>
                <Box sx={{
                  marginLeft: '-33px'
                }}>
                  <Typography variant='body2' sx={{
                    color: '#454F5B',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}>
                    {message.text}
                  </Typography>
                </Box>
              </Alert>
            )
          })}
        </Stack>
      </Box>
    </Snackbar>
  )
}

export {NotificationMessages}
