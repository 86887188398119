import {Box, Button, DialogTitle, IconButton, Stack, Typography} from '@mui/material'
import React from 'react'
import DialogBody from '../dialog-body'
import DialogFooter from '../dialog-footer'
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/Close';
import Filter from '../filters/Filter';
import FilterList from '../filters/FilterList';
import {NOTIFICATIONS_DATE_FILTERS, NOTIFICATIONS_TYPE_FILTERS} from 'src/store/notifications/notificationsTypes';

export default function NotificationsFilters(props) {
  const {
    updateTypeFilter,
    typeFilter,
    updateDateFilter,
    dateFilter,
    showDateModal,
    rangeDateLabel,
    resetNotificationsLayout,
    onApplyFilters,
    onResetFilters,
    close
  } = props

  const onApply = () => {
    onApplyFilters()
    resetNotificationsLayout()
  }

  const onReset = () => {
    onResetFilters()
    resetNotificationsLayout()
  }

  return (
    <>
      <DialogTitle
        sx={{
          padding: "20px",
          backgroundColor: '#fff'
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <IconButton size="large" onClick={resetNotificationsLayout}>
            <ChevronLeft size="small" />
          </IconButton>
          <Typography variant='h5' sx={{color: '#454F5B'}}>
            Фильтры
          </Typography>
          <IconButton size="large" onClick={close}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogBody sx={{backgroundColor: '#fff'}}>
        {/* <Box p={2} px={0}>
          <Filter
            title="Тип уведомления"
            content={(
              <FilterList
                filters={NOTIFICATIONS_TYPE_FILTERS}
                update={updateTypeFilter}
                current={typeFilter}
              />
            )}
          />
        </Box> */}
        <Box p={2} px={0}>
          <Filter
            title="Период"
            content={(
              <FilterList
                filters={NOTIFICATIONS_DATE_FILTERS}
                update={updateDateFilter}
                current={dateFilter}
              />
            )}
            action={(
              <Button
                variant="text"
                onClick={showDateModal}
                sx={{
                  color: "#454F5B"
                }}>
                {rangeDateLabel || 'Выбрать период'}
              </Button>
            )}
          />
        </Box>
      </DialogBody>
      <DialogFooter>
        <Button
          fullWidth
          variant='text'
          size='large'
          onClick={onReset}
        >
          Сбросить все
        </Button>

        <Button
          fullWidth
          variant='contained'
          size='large'
          onClick={onApply}
        >
          Применить
        </Button>
      </DialogFooter>
    </>
  )
}

