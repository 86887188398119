import {format, isToday, isYesterday, parseISO} from 'date-fns';

export const groupNotificationsByDate = (notifications) => {
  const grouped = {
    today: [],
    yesterday: [],
    older: {}
  };

  notifications?.forEach(notification => {
    const date = parseISO(notification.created_at);

    if (isToday(date)) {
      grouped.today.push(notification);
    } else if (isYesterday(date)) {
      grouped.yesterday.push(notification);
    } else {
      const formattedDate = format(date, 'dd MMMM');

      if (!grouped.older[formattedDate]) {
        grouped.older[formattedDate] = [];
      }

      grouped.older[formattedDate].push(notification);
    }
  });

  return grouped;
};

