import React from 'react'
import {Button, Stack, Typography} from '@mui/material';
import {ReactComponent as SearchIcon} from '../../assets/icons/Search.svg';

export default function NotificationsEmpty({
  onReset,
  hasFilters
}) {
  return (
    <Stack
      spacing="8px"
      mt="80px"
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <SearchIcon />
      <Typography variant="h5">
        Нет новых уведомлений
      </Typography>
      <Typography variant='body2' sx={{color: '#637381', maxWidth: '310px'}} align='center'>
        Здесь будут показываться
        новые уведомления
      </Typography>
      {hasFilters && (
        <Button
          size='large'
          onClick={onReset}
          sx={{color: "#454F5B"}}
        >
          Очистить фильтры
        </Button>
      )}
    </Stack>
  )
}

