import PropTypes from 'prop-types';
import {DateRange} from 'react-date-range';
// @mui
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormHelperText from '@mui/material/FormHelperText';
// hooks
import {useResponsive} from 'src/hooks/use-responsive';
import {IconButton, Typography} from '@mui/material';
import Iconify from '../iconify/iconify';
import {ru} from 'date-fns/locale'

// ----------------------------------------------------------------------

export default function CustomDateRangePicker({
  title = 'Select date range',
  variant = 'input',
  //
  startDate,
  endDate,
  //
  onChangeStartDate,
  onChangeEndDate,
  //
  open,
  onClose,
  //
  error,
  rangeDate,
  updateRangeDate,
  onApplyDateRange,
  onResetDateRange,
}) {
  const mdUp = useResponsive('up', 'md');

  const isCalendarView = variant === 'calendar';

  return (
    <Dialog
      fullWidth
      maxWidth={isCalendarView ? false : 'xs'}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          ...(isCalendarView && {
            maxWidth: 720,
            width: '100%',
          }),
        },
      }}
    >
      <DialogTitle sx={{pb: 2}}>
        <Stack direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography align='left' variant="h4">
            {title}
          </Typography>
          <IconButton size="large" onClick={onClose}>
            <Iconify icon="solar:close-circle-bold" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent
        sx={{
          ...(isCalendarView &&
            mdUp && {
            overflow: 'unset',
          }),
          padding: 0
        }}
      >
        <Stack
          justifyContent="center"
          spacing={isCalendarView ? 3 : 2}
          direction={isCalendarView && mdUp ? 'row' : 'column'}
          sx={{pt: 1}}
        >
          {isCalendarView ? (
            <Paper
              sx={{
                borderRadius: 0,
                margin: 'auto'
              }}
            >
              <DateRange
                editableDateInputs={false}
                onChange={updateRangeDate}
                moveRangeOnFirstSelection={false}
                ranges={rangeDate}
                color="#212B36"
                rangeColors={['#212B36', '#212B36', '#212B36']}
                locale={ru}
              />
            </Paper>
          ) : (
            <>
              <DatePicker label="Start date" value={startDate} onChange={onChangeStartDate} />

              <DatePicker label="End date" value={endDate} onChange={onChangeEndDate} />
            </>
          )}
        </Stack>

        {error && (
          <FormHelperText error sx={{px: 2}}>
            End date must be later than start date
          </FormHelperText>
        )}
      </DialogContent>

      <DialogActions sx={{maxWidth: '320px', display: 'flex', justifyContent: 'space-between', margin: 'auto', width: '100%'}}>
        <Button variant="text" color="inherit" size="large" onClick={onResetDateRange}>
          Сбросить
        </Button>
        <Button disabled={error} size="large" variant="contained" onClick={onApplyDateRange}>
          Выбрать
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CustomDateRangePicker.propTypes = {
  error: PropTypes.bool,
  onChangeEndDate: PropTypes.func,
  onChangeStartDate: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  variant: PropTypes.oneOf(['input', 'calendar']),
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
};
