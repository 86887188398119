import React from 'react'
import {Box, Chip} from '@mui/material';

export default function FilterList({
  filters,
  update,
  current,
}) {
  return (
    <Box style={{display: "flex", gap: "12px", flexWrap: "wrap"}}>
      {filters?.map(({value, label}) => {
        return (
          <Chip
            key={value}
            label={label}
            sx={{
              borderRadius: 100,
              fontWeight: 400,
              backgroundColor: "#F4F6F8",
              color: "#454F5B",
              fontSize: 14,
              border: "1px solid transparent",
              ...(value === current && {borderColor: "#919EAB"}),
              '&&:hover': {
                backgroundColor: "#F4F6F8",
              }
            }}
            onClick={() => update(value)}
          />
        )
      })}
    </Box>
  )
}

