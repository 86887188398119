import {debounce} from 'lodash';
import {useCallback} from 'react';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {shortDateLabel} from 'src/components/custom-date-range-picker'
import {notificationsApi} from 'src/store/notifications/notificationsApi';
import {
  getDateParams,
  NOTIFICATIONS_DATE_FILTERS,
  NOTIFICATIONS_DEFAULT_RANGE_DATE,
  NOTIFICATIONS_STATUS_FILTERS,
  NOTIFICATIONS_TYPE_FILTERS,
} from 'src/store/notifications/notificationsTypes';
import isEqual from 'lodash/isEqual';

export function useNotificationsFilters() {
  const defaultStatus = 'all'

  const [triggerFetchNotifications, fetchNotificationsQuery] = notificationsApi.useLazyFetchQuery()
  const {globalNotificationsFilters} = useSelector(state => state.notifications)

  const [typeFilter, setTypeFilter] = useState(null)
  const [dateFilter, setDateFilter] = useState('')
  const [textFilter, setTextFilter] = useState('')
  const [selectedDateRange, setSelectedDateRange] = useState()
  const [rangeDate, setRangeDate] = useState(NOTIFICATIONS_DEFAULT_RANGE_DATE);
  const [tab, setTab] = useState(defaultStatus)
  const [filtersParams, setFiltersParams] = useState(null)

  const updateRangeDate = (item) => setRangeDate([item.selection])
  const updateTypeFilter = (value) => setTypeFilter(value)
  const updateDateFilter = (value) => {
    setDateFilter(value)
    setSelectedDateRange(null)
  }

  const onApplyFilters = () => {
    setFiltersParams(filters => ({
      ...filters,
      type: typeFilter,
      ...(dateFilter ? {
        date: dateFilter,
        dateRange: null
      } : selectedDateRange && {
        date: null,
        dateRange: selectedDateRange
      })
    }))
  }

  const debounceChangeText = useCallback(
    debounce((value) => {
      setFiltersParams(filters => ({
        ...filters,
        text: value,
      }))
    }, 1000),
    [tab]
  )

  const onChangeText = (e) => {
    debounceChangeText(e.target.value)
    setTextFilter(e.target.value)
  }

  const reset = () => {
    setTypeFilter(null)
    setDateFilter(null)
    setSelectedDateRange(null)
    setRangeDate(NOTIFICATIONS_DEFAULT_RANGE_DATE)
  }

  const onClearText = () => {
    setTextFilter('')
    setFiltersParams({
      ...filtersParams,
      text: null
    })
  }

  const onResetFilters = () => {
    reset()
    setFiltersParams(filters => ({
      ...filters,
      type: null,
      date: null,
      dateRange: null,
    }))
  }

  const onResetEmpty = () => {
    reset()
    setTab(defaultStatus)
    setFiltersParams({
      status: defaultStatus,
      type: null,
      date: null,
      text: null,
      dateRange: null
    })
  }

  const onApplyDateRange = () => {
    setDateFilter(null)
    setSelectedDateRange({
      startDate: rangeDate[0]?.startDate,
      endDate: rangeDate[0]?.endDate,
    })
  }

  const onResetDateRange = () => {
    setSelectedDateRange(null)
    setRangeDate(NOTIFICATIONS_DEFAULT_RANGE_DATE)
  }

  const onChangeTabs = (z, e) => {
    setTab(e)
    setFiltersParams(filters => ({
      ...filters,
      status: e
    }))
  }

  const onReload = () => {
    setFiltersParams(() => ({...filtersParams}))
  }

  const rangeDateLabel = selectedDateRange && shortDateLabel(selectedDateRange?.startDate, selectedDateRange?.endDate)

  const appliedFilters = [
    ...NOTIFICATIONS_STATUS_FILTERS?.filter(({value}) => value === filtersParams?.status)?.map(({label}) => ({
      type: 'status',
      label
    })),
    ...NOTIFICATIONS_DATE_FILTERS?.filter(({value}) => value === filtersParams?.date)?.map(({label}) => ({
      type: 'date',
      label
    })),
    ...NOTIFICATIONS_TYPE_FILTERS?.filter(({value}) => value === filtersParams?.type)?.map(({label}) => ({
      type: 'type',
      label
    })),
    ...(filtersParams?.dateRange ? [{
      type: 'dateRange',
      label: rangeDateLabel
    }] : []),
  ]

  const onDeleteAppliedFilters = ({type}) => {
    switch (type) {
      case 'status':
        setTab(defaultStatus)
        setFiltersParams(filters => ({
          ...filters,
          status: defaultStatus,
        }))
        break

      case 'type':
        setTypeFilter(null)
        setFiltersParams(filters => ({
          ...filters,
          type: null,
        }))
        break
      case 'date':
        setDateFilter(null)
        setFiltersParams(filters => ({
          ...filters,
          date: null,
        }))
        break
      case 'dateRange':
        setSelectedDateRange(null)
        setRangeDate(NOTIFICATIONS_DEFAULT_RANGE_DATE)
        setFiltersParams(filters => ({
          ...filters,
          dateRange: null,
        }))
        break
    }
  }

  useEffect(() => {
    triggerFetchNotifications({
      ...filtersParams,
      notification_type: filtersParams?.type,
      status: filtersParams?.status === defaultStatus ? undefined : filtersParams?.status,
      ...getDateParams(filtersParams?.date, filtersParams?.dateRange),
    })
  }, [filtersParams])

  useEffect(() => {
    if (!isEqual(filtersParams, globalNotificationsFilters)) {
      setTab(globalNotificationsFilters?.status || 'all')
      setFiltersParams(globalNotificationsFilters)
    }
  }, [globalNotificationsFilters])

  return {
    tab,
    onChangeTabs,
    appliedFilters,
    onDeleteAppliedFilters,
    updateRangeDate,
    updateTypeFilter,
    updateDateFilter,
    onApplyFilters,
    onResetDateRange,
    onApplyDateRange,
    onResetEmpty,
    onResetFilters,
    onChangeText,
    onClearText,
    filtersParams,
    typeFilter,
    dateFilter,
    rangeDateLabel,
    rangeDate,
    fetchNotificationsQuery,
    onReload,
    textFilter
  };
}
