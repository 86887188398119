import React from 'react'
import {Box, Stack, Typography} from '@mui/material';

export default function Filter({
  title,
  action,
  content,
}) {

  return (
    <>
      <Box mb="20px">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h6" color="#637381">
            {title}
          </Typography>
          {action}
        </Stack>
      </Box >
      {content}
    </>
  )
}

