import {combineReducers} from 'redux'
import {userReducer} from './user/userSlice'
import {bidReducer} from './bid/bidSlice'
import {endpointReducer} from './endpoint/endpoingSlice'
import {appReducer} from './app/appSlice'
import {carReducer} from './car/carSlice'
import {requisiteReducer} from './requisite/requisiteSlice'
import {sellerReducer} from './seller/sellerSlice'
import {bidApi} from './bid/bidApi'
import {sellerApi} from './seller/sellerApi'
import {userApi} from './user/userApi'
import {registersReducer} from './registers/registersSlice'
import {notificationsReducer} from './notifications/notificationsSlice'

const rootReducer = combineReducers({
  app: appReducer,
  user: userReducer,
  [userApi.reducerPath]: userApi.reducer,
  bid: bidReducer,
  [bidApi.reducerPath]: bidApi.reducer,
  car: carReducer,
  endpoint: endpointReducer,
  requisite: requisiteReducer,
  seller: sellerReducer,
  registers: registersReducer,
  notifications: notificationsReducer,
  [sellerApi.reducerPath]: sellerApi.reducer,
});

export {rootReducer};
